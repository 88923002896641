import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <div id='footer'>
      <div id='by-line'>
        <p>Developed by Tran Le &#128029;</p>
      </div>
    </div>
  )
}

export default Footer;